// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  endPoint:'https://cloudide.co.in/workspace/reactor2/public/nifvcperp/api/',
  remoteAssetsEndpoint: 'https://cloudide.co.in/workspace/reactor2/public/',
  isProduction:true,
  storagekeys:{
    authtoken:'AUTH_TOKEN',
    islogin:'login_status',
    loginDetails:'login_details',
    dayDetails:'day_details',
    daySyncTime:'day_sync_time',
    salaryPaymentOpenCash:"salary_payment_openingcash"
  },
  limits:{
    curling:{
      bundle:{
        user:15,
        admin:20
      },
      weight:{
        user:450,
        admin:475
      }
    }
  },
  buildVersion:'210717-B056'
};
if(environment.isProduction){
  environment.endPoint='https://cloudide.co.in/workspace/cerpnif/public/api/';
  environment.remoteAssetsEndpoint='https://cloudide.co.in/workspace/cerpnif/public/';
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
