import { Component,Input, OnInit } from '@angular/core';
import {  AbstractControl, FormControl, NgForm } from '@angular/forms';

@Component({
  selector: 'app-td-validation-error',
  templateUrl: './td-validation-error.component.html',
  styleUrls: ['./td-validation-error.component.scss'],
})
export class TdValidationErrorComponent implements OnInit {
  @Input() validationMessage:{};
  @Input() fieldName:string
  _validationForm:NgForm|AbstractControl
  _formControl
  constructor() { }

  ngOnInit() {}
  @Input()
  set validationForm(val: NgForm|AbstractControl) {
    this._validationForm = val;
    setTimeout(() => {
      this._formControl=this._validationForm['controls'][this.fieldName];
    }, 1000);
  }
}
