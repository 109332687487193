import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { BehaviorSubject,Observable } from 'rxjs';
import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { environment } from 'src/environments/environment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
pdfMake.vfs = pdfFonts.pdfMake.vfs;


@Injectable({
  providedIn: 'root'
})
export class AppHelperService {
  dayStatus= new BehaviorSubject(false);
  isDayOpen= new BehaviorSubject(false);
  shiftStatus= new BehaviorSubject(false);
  lastSync:any={};
  dayDetails:any=null;
  settings={
    appSettings:null,
    curlingSettings:null
  }
  constructor(private storage:Storage, private platform:Platform,private api:ApiService,private auth:AuthService) {

    this.platform.ready().then(() => {

      // this.previousStatus=ConnectionStatusEnum.Online;
      // this.storage.get(environment.storagekeys.authtoken).then(data=>{
      //   console.log('token',data);
      //   this.authToken=data;
      //   this.tokenStatus.next(true);
      // });
      // this.storage.get(environment.storagekeys.loginDetails).then(data=>{
      //   this.loginUser=data;
      // });
      this.api.tokenStatusCheck().subscribe((data:any)=>{
        if(data)
          this.appSync();
      })
    });
  }
  appSync(manual=false){
    if(this.api.tokenStatus.value){
      this.api.postWtkn('general/sync').subscribe((d:any)=>{
        if(d.status){
          if(!this.lastSync.dayDetails||this.lastSync.dayDetails!=d.data.dayDetails){
            this.api.postWtkn("dayinfo/getDayInfo")
            .subscribe((day:any)=>{
              if(day.status){
                this.lastSync['dayDetails']=d.data.dayDetails
                if(day.data[0]){
                  this.dayDetails=day.data[0];
                  this.storage.set(environment.storagekeys.dayDetails,day.data[0]);
                  this.isDayOpen.next( !this.dayDetails.isDayClosed)
                }
                this.dayStatus.next(true);
              }
            },
            (error)=>{ console.log(error);
            })
          }
          if(!this.lastSync.settings||this.lastSync.settings!=d.data.settings){
            if(!this.lastSync.appSettings||this.lastSync.appSettings!=d.data.settingsVersions.appSettings)
              this.api.postWtkn("settings/getsettings",{key:"appSettings"})
              .subscribe((settings:any)=>{
                if(settings.status){
                  this.lastSync['settings']=d.data.settings
                  if(settings.data){
                    this.settings.appSettings=settings.data;
                  }
                }
              },
              (error)=>{ console.log(error);
              })
            if(!this.lastSync.curlingSettings||this.lastSync.curlingSettings!=d.data.settingsVersions.curlingSettings)
              this.api.postWtkn("settings/getsettings",{key:"curlingSettings"})
              .subscribe((settings:any)=>{
                if(settings.status){
                  this.lastSync['settings']=d.data.settings
                  if(settings.data){
                    this.settings.curlingSettings=settings.data;
                  }
                }
              },
              (error)=>{ console.log(error);
              })
          }
        }
      })
    }
    if(!manual)
    setTimeout(() => {
      this.appSync();
    }, 30000);
  }
  getDayStatus(){
    return this.dayStatus.asObservable();
  }
  getDayDetails(){
      return (this.dayDetails==null?null:JSON.parse(JSON.stringify(this.dayDetails)));
  }
  getSettings(key){
    console.log(this.settings[key]);
    return (this.settings[key]==null?null:JSON.parse(JSON.stringify(this.settings[key])));
  }
  pdfGen(context,options?:{pageSize?:'A3'|'A4'|'A5'|'A6', orientation?:'landscape'|'portrait',
    callMode?:'pdf'|'print',fileName?:String}){
    const documentDefinition = {content: context,
      pageSize: options.pageSize?options.pageSize:'A4',
      pageOrientation: options.orientation?options.orientation:'portrait',
    };
    let filename=options.fileName?options.fileName:'download';
    switch (options.callMode) {
      case 'pdf':
        pdfMake.createPdf(documentDefinition).download(filename+".pdf");
        break;
      case 'print':
        pdfMake.createPdf(documentDefinition).print();
        break;
      default:
        pdfMake.createPdf(documentDefinition).print();
        break;
    }
  }
}
