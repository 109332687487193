import { Component } from '@angular/core';
import { LoadingController, Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { environment } from 'src/environments/environment';
import { AppHelperService } from './services/app-helper.service';
import { AuthService } from './services/auth.service';
import { UxHelpersService } from './services/ux-helpers.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  constructor(private auth:AuthService, private storage:Storage, private app:AppHelperService,
    private platform:Platform) {
  }
}
