import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BackButtonComponent } from './back-button/back-button.component';
import { IonicModule } from '@ionic/angular';
import { ValidationErrorComponent } from './validation-error/validation-error.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TdValidationErrorComponent } from './td-validation-error/td-validation-error.component';
import { CustomMaxDirective } from './validators/custom-max-validator.directive';
import { CustomMinDirective } from './validators/custom-min-validator.directive';
import { QrScannerComponent } from './qr-scanner/qr-scanner.component';
import { MenuButtonComponent } from './menu-button/menu-button.component';

@NgModule({
  declarations: [BackButtonComponent,MenuButtonComponent,ValidationErrorComponent,TdValidationErrorComponent, CustomMaxDirective, CustomMinDirective, QrScannerComponent],
  imports: [
    CommonModule,IonicModule,ReactiveFormsModule,FormsModule
  ],
  exports:[BackButtonComponent,MenuButtonComponent,ValidationErrorComponent,TdValidationErrorComponent,CustomMaxDirective,CustomMinDirective, QrScannerComponent]
})
export class SharedModule { }
