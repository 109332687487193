import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Storage } from '@ionic/storage';
import { Platform } from '@ionic/angular';
import { fromEvent,BehaviorSubject,Observable,Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { UxHelpersService } from './ux-helpers.service';

export enum ConnectionStatusEnum{
  Online,
  Offline
}
@Injectable({
  providedIn: 'root'
})
export class ApiService {
  onlineEvent: Observable<Event>;
  offlineEvent: Observable<Event>;
  subscriptions: Subscription[] = [];
  connectionStatusMessage: string;

  authToken='';
  public loginUser:any;
  public isOnline:boolean=true;
  private previousStatus;
  tokenStatus= new BehaviorSubject(false);
  connectionStatus= new BehaviorSubject(false);
  constructor(public http: HttpClient, private storage:Storage, private platform:Platform, private ux:UxHelpersService) {
    this.platform.ready().then(() => {
      this.previousStatus=ConnectionStatusEnum.Online;
      this.storage.get(environment.storagekeys.authtoken).then(data=>{
        if(data!=null){
          this.authToken=data;
          this.tokenStatus.next(true);
        }
      });
      this.storage.get(environment.storagekeys.loginDetails).then(data=>{
        this.loginUser=data;
      });
    });

    console.log("API init");
    this.onlineEvent = fromEvent(window, 'online');
    this.offlineEvent = fromEvent(window, 'offline');

    this.subscriptions.push(this.onlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Back to online';
      this.connectionStatus.next(true);
      this.isOnline=true;
      this.ux.presentToast(this.connectionStatusMessage);
    }));

    this.subscriptions.push(this.offlineEvent.subscribe(e => {
      this.connectionStatusMessage = 'Connection lost! You are not connected to internet';
      this.connectionStatus.next(false);
      this.isOnline=false;
      this.ux.presentToast(this.connectionStatusMessage);
    }));
   }

   httpOptions:any = {
    headers: new HttpHeaders({
    'Content-Type': 'application/json',
    })
  };

  ngOnInit(): void {
    /**
    * Get the online/offline status from browser window
    */
  }
  tokenStatusCheck(){
    return this.tokenStatus.asObservable();
  }
  post(endpoint:string,body:any={}){
    return this.http.post(environment.endPoint+endpoint,JSON.parse(body),this.httpOptions);
  }
  postWtkn(endpoint:string,body:any={}){
    // console.log(endpoint,body, this.authToken)
      let options={
        headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Token':this.authToken
        })
      };
      return this.http.post(environment.endPoint+endpoint,body,options);
  }
  setToken(token:string=null){
    this.storage.set(environment.storagekeys.authtoken,token)
    .catch(e=>console.log(e))
    .then(s=>{
      this.authToken=token;
      this.tokenStatus.next(true);
    });
  }

}
